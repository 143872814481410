<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible && tableClicked == tableId }"
      :style="{ visibility: visible && tableClicked == tableId ? 'visible' : 'hidden', width: width }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="settings" /></span>
          <slot
            name="info-sheet-title"
          />
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <slot
        name="info-sheet"
      />
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible && tableClicked == tableId }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    tableId: {
      type: Number,
      required: false,
      default: null,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      visible: 'modals/infoSheet',
      tableClicked: 'modals/tableClicked',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
      this.$emit('closed', true)
    },
  },
}
</script>
